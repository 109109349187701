"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.update = exports.findOne = exports.findAll = void 0;
const core_1 = require("./core");
function findAll(page = 1, search) {
    return core_1.get('Atleta', 'lista', Object.assign({ pg: page }, search))
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findAll = findAll;
function findOne(id) {
    return core_1.get('Atleta', 'detalhe', { id });
}
exports.findOne = findOne;
function update(collAtletas) {
    return core_1.put('Atleta', 'update', {
        collAtletas
    });
}
exports.update = update;
