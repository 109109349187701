<template>
  <div class="background">
    <v-container class="spacing-playground pa-lg-10" fluid>
      <v-card class="d-flex flex-column fundo-transparente" :loading="loading">
        <v-card-title class="d-flex justify-center align-center fundo-chapado">
          <div class="logo text-center">
            <app-logo/>
          </div>
        </v-card-title>
        <v-divider/>
        <div :style="`overflow: auto; max-height: calc(100vh - ${190 + ($vuetify.breakpoint.lgAndUp ? 14 : 0)}px)`">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <CardPersonalizado 
                  title="Jogadores"
                  icon="account-multiple-plus"
                  :action="addJogador"
                  :showConteudo="true"
                  :loading="loading"
                  >
                  <v-row justify="center" align="center">
                    <v-col cols="12" class="d-flex justify-center">
                      <v-btn small color="success" @click="save()">
                        SALVAR ALTERAÇÕES
                      </v-btn>                         
                    </v-col>
                  </v-row>
                  <v-form ref="form" @submit.prevent="save" v-model="formValid" lazy-validation>
                    <v-row v-for="(jogador, index) in baba.jogadores" :key="index">                
                        <v-col cols="12" sm="6">
                          <v-text-field 
                            label="* Nome"
                            dense
                            v-model.trim="jogador.nome"                  
                        />
                        </v-col>
                        <v-col cols="9" sm="4">
                          <v-select
                            :items="posicoes"
                            item-text="nome"
                            label="* Posição"
                            dense
                            v-model="jogador.posicao"
                              />
                        </v-col>
                        <v-col cols="3" sm="2">
                          <v-btn small color="error" @click="removeJogador(index)">
                            <v-icon>mdi-account-multiple-minus</v-icon>
                          </v-btn>                         
                        </v-col>                           
                    </v-row>
                  </v-form>
                </CardPersonalizado>
              </v-col>
              <v-col cols="12" sm="12" v-if="exibirEnquete">
                <CardPersonalizado 
                  title="Enquetes"
                  icon="account-group"
                  iconColor="secundary"
                  :showConteudo="true"
                  :loading="loading"
                  >
                  <h3>{{ enquete.titulo }}</h3>
                  <div v-for="(opcao, index) in enquete.opcoes" :key="index">
                    <v-checkbox
                      :label="opcao.opcao"
                      :value="opcao.opcao"
                      v-model="selectedOpcoes"
                      @change="handleVoteChange(opcao.opcao)"
                      :disabled="hasVoted(opcao.opcao)"
                    ></v-checkbox>
                  </div>
                  <v-btn @click="submitVotes" :disabled="selectedOpcoes.length === 0">Votar</v-btn>
                  <div v-if="hasVotedAnyOption">
                    <h4>Resultados:</h4>
                    <ul>
                      <li v-for="(resultado, index) in enquete.resultado" :key="index">
                        {{ resultado.opcao }}: {{ resultado.qudVotos }} votos
                      </li>
                    </ul>
                  </div>
                </CardPersonalizado>
              </v-col>
              <v-col cols="12" sm="12">
                <CardPersonalizado 
                  title="Times Sorteados"
                  icon="seal"
                  iconColor="secundary"
                  :showConteudo="true" 
                  :loading="loading"             
                  >
                  <v-row>                
                    <v-col cols="3" sm="3">
                      <v-text-field 
                        label="* Jogadores por time"
                        dense
                        v-model.trim="qtdJogadoresLinha"/>
                    </v-col>
                    <v-col cols="6" sm="6">
                      <v-checkbox
                        dense
                        v-model="incluirGoleiros"
                        label="Incluir Goleiros?"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3" sm="3">
                      <v-btn small color="error" @click="sortearTimes">
                        <v-icon>mdi-reload</v-icon>
                      </v-btn>                         
                    </v-col>                           
                  </v-row>
                  <v-row>                
                    <v-col color="yellow" cols="12" sm="6" v-for="(time, index) in times" :key="index">
                      <CardPersonalizado
                        :title="'Time ' + (index + 1)"
                        icon="seal"
                        iconColor="secundary"
                        :showConteudo="true"                 
                        >
                        <ul>
                          <li v-for="jogador in time" :key="jogador.nome">
                            <span v-if="jogador.posicao === 'Goleiro'">
                              <b>{{ jogador.nome }} - {{ jogador.posicao }}</b>
                            </span>
                            <span v-else>
                              {{ jogador.nome }} - {{ jogador.posicao }}
                            </span>
                          </li>
                        </ul>
                      </CardPersonalizado>
                    </v-col>                           
                  </v-row>                  
                </CardPersonalizado>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AppLogo from "../../components/app/AppLogo";
import CardPersonalizado from '../../components/shared/common/CardPersonalizado';
import {findAll, update} from '@/api/atleta';
import {ApiError} from "@/api/core";

export default {
  components: {AppLogo, CardPersonalizado},
  props: {
  },
  name: "Login",
  data() {
    return {
      formValid: false,
      exibirEnquete: false,
      processing: false,
      loading: false,
      error: null,
      posicoes: [
        {nome: "Goleiro"},
        {nome: "Zagueiro"},
        {nome: "Lateral E"},
        {nome: "Lateral D"},
        {nome: "Meio"},
        {nome: "Atacante"},
        {nome: "Linha"},
      ],
      baba: {
        jogadores: [
            ],
        dias:[
            {
                times: [
                    {
                        jogador: ""
                    }
                ] 
            }
        ]
      },
      qtdJogadoresLinha: 5,
      incluirGoleiros: true,
      times: [],
      enquete: {
        titulo: "Qual o melhor dia do baba",
        opcoes: [
          { opcao: "01/07/2024" },
          { opcao: "01/08/2024" },
          { opcao: "01/09/2024" },
          { opcao: "01/10/2024" },
        ],
        resultado: [
          { opcao: "01/07/2024", qudVotos: 0 },
          { opcao: "01/08/2024", qudVotos: 0 },
          { opcao: "01/09/2024", qudVotos: 0 },
          { opcao: "01/10/2024", qudVotos: 0 },
        ],
      },
      selectedOpcoes: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['user', 'token', 'isAuthenticated']),
    hasVotedAnyOption() {
      return this.enquete.resultado.some(opcao => localStorage.getItem(opcao.opcao));
    },    
  },
  methods: {
    ...mapActions('auth', ['doLogin']),
    addJogador() {
      this.baba.jogadores.unshift({
        nome: "", posicao: ""
      });
    },
    removeJogador(index) {
      this.baba.jogadores.splice(index, 1);
    }, 
    handleVoteChange(opcao) {
      // Função opcional se precisar de alguma lógica específica ao mudar a seleção
    },
    hasVoted(opcao) {
      return localStorage.getItem(opcao) !== null;
    },
    submitVotes() {
      this.selectedOpcoes.forEach(opcao => {
        if (!this.hasVoted(opcao)) {
          localStorage.setItem(opcao, 'voted');
          const resultado = this.enquete.resultado.find(res => res.opcao === opcao);
          if (resultado) {
            resultado.qudVotos += 1;
          }
        }
      });
      this.selectedOpcoes = [];
    },
    sortearTimes() {
      function sortearTimes(jogadores, numJogadoresLinha, incluirGoleiross) {
        let goleiros = jogadores.filter(jogador => jogador.posicao === "Goleiro");
        let jogadoresLinha = jogadores.filter(jogador => jogador.posicao !== "Goleiro");

        goleiros = goleiros.sort(() => Math.random() - 0.5);
        jogadoresLinha = jogadoresLinha.sort(() => Math.random() - 0.5);
        const qtdJogDisp = jogadoresLinha.length;
        const qtdGolDisp = goleiros.length;
        const numTimes = Math.ceil(jogadoresLinha.length / numJogadoresLinha);
        const times = Array.from({ length: numTimes }, () => []);
        console.log("numTimes: " + numTimes);
        console.log("total jogadores: " + jogadoresLinha.length);
        console.log("numJogadoresLinha: " + numJogadoresLinha);
        
        // distribuir goleiros
        if (incluirGoleiross){
          for (let i = 0; i < qtdGolDisp; i++) {
            let aux = goleiros.shift();
            times[i].push(aux);
          }
        }

        // distribuir jogadores
        let numTimesAux = 0;
        for (let i = 0; i < qtdJogDisp; i++) {
          times[numTimesAux].push(jogadoresLinha[i]);
          if ( (i+1) === (numJogadoresLinha*(numTimesAux+1)) && i < (qtdJogDisp-1)){
            numTimesAux++;
          }
        }
        return times;
      }
      this.times = sortearTimes(this.baba.jogadores, this.qtdJogadoresLinha, this.incluirGoleiros);
    },
    async refresh() {
      try {
        this.error = null;
        this.loading = true;
        const page = await findAll(1, this.searchValues);
        this.baba.jogadores = page.elements;
      } catch (err) {
        this.error = err;
        this.baba.jogadores = [];        
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível carregar a lista de Atletas.`, {
            timeout: 6000
          })
        }   
      } finally {
        this.loading = false;
      }
    }, 
    async save() {
      try {
        if (this.listaEstaTodaPreenchida()) {
          let stringJogadores = this.baba.jogadores.map(jogador => `${jogador.nome};${jogador.posicao}`).join(':');
          console.log(stringJogadores);
          await update(stringJogadores);
          this.loading = true;
          this.refresh();
          this.sortearTimes();
          this.$toast.success(`Atletas atualizados com sucesso.`, {
            timeout: 3000
          })
        } else {
          this.$toast.error(`Existe Atleta na lista faltando informação.`, {
            timeout: 6000
          })
        }
      } catch (err) {
        if(err instanceof ApiError) {
          this.$toast.error(err.message, {
           timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível atualizar a lista de Atletas.`, {
            timeout: 6000
          })
        }
      } 
    },
    listaEstaTodaPreenchida(){
      let jogadoresInvalidos = this.baba.jogadores.filter(jogador => !jogador.nome || !jogador.posicao);
      return jogadoresInvalidos.length > 0 ? false : true;
    }
  },
  mounted(){
    this.refresh();
  }
}
</script>

<style scoped lang="scss">

.background {
  background-image: url('../../assets/backgrounds/bg01.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  flex: 1;
  .logo {
    padding-top: 15px;
    img {
      height: 60px;
    }
  }
  .fundo-transparente {
    background-color: rgba(255, 255, 255, 0.9) !important;
  }
  .fundo-chapado {
    background-color: rgba(255, 255, 255, 1) !important;
  }
}
</style>